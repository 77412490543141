<script>
import debounce from "lodash/debounce";
import _ from 'lodash';
import SelectInput from "@/components/Form/SelectInput.vue";

export default {
    name: "RoleSelection",
    components: {SelectInput},
    model: {
        prop: 'value',
        event: 'input'
    },
    props: {
        label: {
            type: String,
            required: true
        },

        id: {
            type: String,
            required: true
        },

        value: {
            type: [String, Number, Array],
            default: null
        }
    },
    data() {
        return {
            items: [],

            loading: false,
            selected: [],
            itemAdd: null,
            searchValue: null,
            innerValue: null
        }
    },

    methods: {
        updateValue(value) {
            this.innerValue = value
            this.$emit('input', this.innerValue)
        },

        clear() {
            this.updateValue([])
            this.items = [];
        },

        fetchRoles() {
            this.loading = true;
            this.$http.get('admin/role').then(response => {
                this.items = response.body.data;
            }).finally(() => {
                this.loading = false;
            });
        },

        input(value) {
            this.updateValue(value);
        },
    },

    mounted() {
        this.fetchRoles()
    }
}
</script>

<template>
    <div>
        <SelectInput
            v-bind="$attrs"
            :items="items"
            :id="id"
            :label="label"
            :placeholder="$t('chose_role')"
            :value="value"
            @input="input"
            item-text="display_name"
            item-value="id"
        ></SelectInput>
    </div>
</template>

<style scoped>

</style>
