<script>
import debounce from "lodash/debounce";
import _ from 'lodash';
import SelectInput from "@/components/Form/SelectInput.vue";
import {buildListArrayFromTreeArray, buildTree} from "@/plugins/functions";

export default {
    name: "DepartmentSelection",
    components: {SelectInput},
    model: {
        prop: 'value',
        event: 'input'
    },
    props: {
        label: {
            type: String,
            required: true
        },

        id: {
            type: String,
            required: true
        },

        value: {
            type: [String, Number, Array],
            default: null
        }
    },
    data() {
        return {
            items: [],

            loading: false,
            selected: [],
            itemAdd: null,
            searchValue: null,
            innerValue: null
        }
    },

    methods: {
        updateValue(value) {
            this.innerValue = value
            this.$emit('input', this.innerValue)
        },

        clear() {
            this.updateValue([])
            this.items = [];
        },

        fetchRoles() {
            this.loading = true;
            this.$http.get('admin/department').then(response => {
                let deps = response.body.data;

                deps.forEach((item) => {
                    let repeatCount = (item.depth ?? 1) - 1;
                    item.name = ('. '.repeat(repeatCount)) + item.name;
                    return item;
                })

                deps = buildTree(deps, 0, 'id', 'parent_id', 'children');
                deps = buildListArrayFromTreeArray(deps);

                this.items = deps;
            }).finally(() => {
                this.loading = false;
            });
        },

        input(value) {
            this.updateValue(value);
        },
    },

    mounted() {
        this.fetchRoles()
    }
}
</script>

<template>
    <SelectInput
        v-bind="$attrs"
        :items="items"
        :id="id"
        :label="label"
        :placeholder="$t('chose_department')"
        :value="value"
        @input="input"
        item-text="name"
        item-value="id"
    ></SelectInput>
</template>

<style scoped>

</style>
