<template>
    <PageLayout>
        <template>
            <TabsNav/>
            <div class="d-flex justify-space-between">
                <div class="d-flex align-center">
                    <h1>{{ filter_show ? $t('search_administrator') : $t('roles') }}</h1>
                </div>
            </div>

            <v-row>
                <v-col>
                    <SelectInput
                        id="type_client"
                        :label="$t('type_client')"
                        :items="typeClients ?? []"
                        :item-text="'name'"
                        :item-value="'id'"
                        v-model="typeClient"
                        background-color='white'
                    ></SelectInput>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <ResizableTable
                        class="main-data-table"
                        :columns="columns"
                        :rows="rows"
                    >
                        <template v-slot:header="{ props }">
                            <thead>
                            <tr>
                                <th
                                    v-for="(column, index) in columns"
                                    :key="index"
                                >
                                    <div class="resizable-table__cell">
                                        {{ column.label }} [{{ column.role?.id }}]
                                    </div>
                                </th>
                            </tr>
                            </thead>
                        </template>
                        <template v-for="(column) in columns" v-slot:[column.prop]="{ item: permission }">
                            <div :key="column.prop">
                                <template v-if="column.prop.substring(0, 5) === 'role_'">
                                    <template v-if="column.prop !== 'role_other'">
                                        <v-switch
                                            :key="column.prop"
                                            :input-value="permission[column.prop]"
                                            @change="$event ? addPermission(typeClient, column.role.id, permission.id) : deletePermission(typeClient, permission[column.prop])"
                                        ></v-switch>
                                    </template>
                                    <template v-else>
                                        <template v-if="permission[column.prop] !== null">
                                            <div v-if="permission[column.prop].others" :key="column.prop">
                                                <v-chip
                                                    small
                                                    v-for="(other, key) in permission[column.prop].others" v-bind:key="other.id"
                                                    close
                                                    close-icon="mdi-trash-can-outline"
                                                    label

                                                    class="mr-1 mb-1"
                                                    @click:close="confirmAndDeleteOther(typeClient, permission[column.prop]['id'], other.id)"
                                                >
                                                    {{$t('role_other_' + other.entity_type)}}: {{ other.entity.name }}
                                                </v-chip>
                                            </div>
                                            <Btn
                                                small
                                                @click="openOtherDialog(column.role.id, permission.id, permission[column.prop]['id'])"
                                            >
                                                <v-icon small>mdi-plus</v-icon>
                                                Добавить право доступа
                                            </Btn>
                                        </template>
                                        <template v-else>
                                            <Btn
                                                small
                                                @click="openOtherDialog(column.role.id, permission.id, permission[column.prop])"
                                            >
                                                Добавить право доступа
                                            </Btn>
                                        </template>
                                    </template>
                                </template>
                                <template v-else>
                                    {{ permission[column.prop] }}
                                </template>
                            </div>
                        </template>
                    </ResizableTable>

                    <v-dialog
                        v-model="otherDialog"
                        width="500"
                    >
                        <ValidationObserver tag="div" v-slot="{ invalid }">
                            <v-card>
                                <v-card-title class="text-h5 grey lighten-2">
                                    Добавить права доступа
                                </v-card-title>
                                <v-card-text>
                                    <ValidationProvider ref="entityType" rules="required">
                                        <SelectInput
                                            id="entity_type"
                                            label="Тип доступа"
                                            v-model="entityType"
                                            :items="[
                                                {
                                                    label: 'Пользователь',
                                                    value: 'admins'
                                                },
                                                {
                                                    label: 'Роль',
                                                    value: 'role'
                                                },
                                                {
                                                    label: 'Отдел',
                                                    value: 'department'
                                                }
                                            ]"
                                            item-value="value"
                                            item-text="label"
                                        ></SelectInput>
                                    </ValidationProvider>

                                    <ValidationProvider ref="entityId" rules="required|integer" v-slot="{ invalid, valid }">
                                        {{entityId}}
                                        {{entityType}}
                                        <template v-if="entityType === 'admins'">
                                            <UserSelection
                                                id="entity_id"
                                                :label="$t('user')"
                                                :value="entityId"
                                                @input="onUserSelect"
                                                :error="invalid"
                                            />
                                        </template>
                                        <template v-else-if="entityType === 'role'">
                                            {{ entityId }}
                                            <RoleSelection
                                                id="entity_id"
                                                :label="$t('role')"
                                                v-model="entityId"
                                                :error="invalid"
                                            />
                                        </template>
                                        <template v-else-if="entityType === 'department'">
                                            <DepartmentSelection
                                                id="entity_id"
                                                :label="$t('department')"
                                                v-model="entityId"
                                                :error="invalid"
                                            />
                                        </template>
                                    </ValidationProvider>
                                </v-card-text>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <Btn
                                        @click="closeOtherDialog"
                                    >
                                        {{ $t('cancel') }}
                                    </Btn>
                                    <Btn
                                        color="secondary"
                                        :disabled="invalid"
                                        @click="saveOther(typeClient, roleId, permissionId, entityType, entityId, typePermissionId)"
                                    >
                                        {{$t('save')}}
                                    </Btn>
                                </v-card-actions>
                            </v-card>
                        </ValidationObserver>
                    </v-dialog>
                </v-col>
            </v-row>
        </template>
    </PageLayout>
</template>
<script>
import PageLayout from "@/components/Leentech/PageLayout.vue";
import {mapActions} from "vuex"
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {mask} from 'vue-the-mask'
import FilterView from "@/plugins/mixins/FilterView";
import TabsNav from "@/components/Leentech/TabsNav.vue";
import ResizableTable from "@/components/Leentech/ResizableTable.vue";
import SelectInput from "@/components/Form/SelectInput.vue";
import UserSelection from "@/components/Form/UserSelection.vue";
import RoleSelection from "@/components/Form/RoleSelection.vue";
import DepartmentSelection from "@/components/Form/DepartmentSelection.vue";
import Btn from "@/components/Form/Btn.vue";

export default {
    name: "UvedPermission",

    directives: {
        mask,
    },

    mixins: [FilterView],

    components: {
        DepartmentSelection,
        UserSelection,
        SelectInput,
        ResizableTable,
        TabsNav,
        ValidationProvider,
        ValidationObserver,
        PageLayout,
        RoleSelection,
        Btn
    },

    data() {
        return {
            entityName: 'role',
            roles: [],
            permissions: [],
            role: false,
            typeClients: null,
            typeClient: null,
            typeClientPermissions: [],

            fab: false,

            totalVisiblePag: 7,
            totalItems: 0,
            loading: false,
            filter_show: false,
            columns: [],
            rows: [],

            typePermissionId: null,
            otherDialog: false,
            roleId: null,
            permissionId: null,
            entityType: null,
            entityId: null,
            others: [],
        }
    },

    watch: {
        typeClients: function (val, oldVal) {
            if (oldVal === null && val[0]) {
                this.typeClient = val[0].id
            }
        },

        typeClient: function (val) {
            this.fetchPermissionsForType(val);
        },

        roles(val) {
            this.columns = this.getColumns(val);
        },

        entityType(val) {
            this.entityId = null
        }
    },

    computed: {
        otherParams() {
            if(this.entityType === 'all') {
                return 'all'
            } else {
                return {
                    entity_type: this.entityType,
                    entity_id: this.entityId
                }
            }
        }
    },

    mounted() {
        this.getItems()
    },

    methods: {
        ...mapActions(['setFormAdministrator']),

        init() {
            this.fetchTypesClient();
            this.fetchPermissions();
            this.getRoles();
        },

        getItems() {

        },

        getRoles() {
            this.$http.get('admin/uved_role')
                .then(({body}) => {
                    this.roles = body.data
                })
                .catch(err => {
                    this.roles = []
                })
        },

        rowClass() {
            return (!this.$vuetify.breakpoint.xsOnly) ? 'rowClass Table5' : 'rowMobiClass';
        },

        addPermission(typeClient, roleId, permissionId) {
            return this.$http.post(`admin/type_client/${typeClient}/permission`, {
                role: roleId,
                permission: permissionId
            })
                .then(res => {
                    this.fetchPermissionsForType(typeClient)
                    return Promise.resolve(res.body);
                })
                .catch(err => {
                    this.$toastr.error(this.$t('uved_permissions_not_updated'))
                    return Promise.reject(err);
                })
        },

        deletePermission(typeClient, permissionId) {
            this.$http.delete(`admin/type_client/${typeClient}/permission/${permissionId}`)
                .then(res => {
                    this.$toastr.success(this.$t('uved_permissions_updated'))
                    this.fetchPermissionsForType(typeClient)
                })
                .catch(err => {
                    this.$toastr.error(this.$t('uved_permissions_not_updated'))
                })
        },

        fetchPermissionsForType(typeId) {
            this.loading = true
            this.$http
                .get("admin/type_client/" + typeId + "/permission")
                .then(({body}) => {
                    this.typeClientPermissions = body.data
                    this.rows = this.getRows();
                })
                .catch(err => {
                    this.typeClientPermissions = []
                    this.$toastr.error(this.$t('failed_to_get_list_roles'))
                    this.$toastr.error(err);
                })
                .finally(end => {
                    this.loading = false
                })
        },

        fetchTypesClient() {
            this.$http.get('admin/type_client')
                .then(({body}) => {
                    this.typeClients = body.data
                })
                .catch(err => {
                    this.typeClients = []
                })
        },

        fetchPermissions() {
            this.loading = true
            this.$http.get('admin/uved_permission', {
                query: {
                    lang: this.lang
                }
            })
                .then(({body}) => {
                    this.permissions = body.data
                    this.loading = false
                })
        },

        addOther(typeClient, typePermissionId, entityType, entityId) {
            this.$http.post(
                `admin/type_client/${typeClient}/permission/${typePermissionId}/other`,
                {
                    entity_type: entityType,
                    entity_id: entityId
                })
                .then(res => {
                    this.$toastr.success(this.$t('uved_permissions_updated'))
                    this.fetchPermissionsForType(typeClient)
                    this.closeOtherDialog()
                })
        },

        deleteOther(typeClient, typePermissionId, id) {
            this.$http.delete(
                `admin/type_client/${typeClient}/permission/${typePermissionId}/other/${id}`)
                .then(res => {
                    this.$toastr.success(this.$t('uved_permissions_updated'))
                    this.fetchPermissionsForType(typeClient)
                    this.closeOtherDialog()
                })
        },

        confirmAndDeleteOther(typeClient, typePermissionId, id) {
            if(confirm(this.$t('confirm_delete'))) {
                this.deleteOther(typeClient, typePermissionId, id)
            }
        },

        saveOther(typeClient, roleId, permissionId, entityType, entityId, typePermissionId = null) {
            if(!typePermissionId) {
                this.addPermission(typeClient, roleId, permissionId)
                    .then((typePermission) => {
                        this.addOther(typeClient, typePermission.id, entityType, entityId)
                    })
            } else {
                this.addOther(typeClient, typePermissionId, entityType, entityId)
            }
        },

        getRows() {
            let items = [];
            for (let p in this.permissions) {
                let permission = this.permissions[p];
                let row = {
                    id: permission.id,
                    name: `${permission.display_name} [${permission.id}]`,
                };

                if (this.roles) {
                    for (let r in this.roles) {
                        let role = this.roles[r];

                        let typePerm = this.typeClientPermissions.find((item) => {
                            return item.role_id === role.id && item.permission_id === permission.id
                        });

                        if (typePerm) {
                            if (role.slug === 'other') {
                                row['role_' + role.slug] = {
                                    id: typePerm.id,
                                    others: typePerm.others
                                };
                            } else {
                                row['role_' + role.slug] = typePerm.id;
                            }
                        } else {
                            row['role_' + role.slug] = null;
                        }
                    }
                }

                items.push(row)
            }

            return items;
        },

        getColumns(roles) {
            let cols = [];

            cols.push({
                active: true,
                label: this.$t('permission_name'),
                align: "left",
                sortable: true,
                prop: "name"
            });

            for (let r in roles) {
                let role = roles[r];

                cols.push({
                    active: true,
                    label: role.name,
                    align: "left",
                    prop: 'role_' + role.slug,
                    role: role
                })
            }

            return cols
        },

        openOtherDialog(roleId, permissionId, id = null) {
            this.roleId = roleId;
            this.permissionId = permissionId;
            this.typePermissionId = id;
            this.entityType = null;
            this.entityId = null;
            this.others = [];
            this.otherDialog = true;
        },

        closeOtherDialog() {
            this.roleId = null;
            this.permissionId = null;
            this.entityType = null;
            this.entityId = null;
            this.others = [];
            this.otherDialog = false;
        },

        allowAll(typeClient, roleId, permissionId, id) {
            this.savePermission(typeClient, roleId, permissionId, "all", id)
        },

        onUserSelect(value) {
            this.entityId = value.id
        }
    }
}
</script>
